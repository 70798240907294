import React, { createContext, useState } from 'react';

// Create Context
export const AppContext = createContext({});

// Provider Component
export const AppProvider = ({ children }) => {
  const [vars, setVars] = useState({
    //  Agent information 
    npn:'', //  From url /?npn=
    username:'',  //  Fetched form agents.username
    //  Location information
    zipcode:'', //  Fetched from Location page
    countyfips:'',  //  Zipcode may be in multiple counties and states. Multi9ple counties displayed to select from
    countyname:'',
    statecode:'',
    statename:'',
    //Requestor information from Request page
    rname:'',
    rphone:'',
    remail:'',
    //  When selected, requestor gives permission to agent to call
    canphone: false,
    //  When selected, requestor gives permission to agent to email
    canemail: false
    //  Two options: 
    //  1.  When requestor information is bank, display PDF only
    //  2.  When requstor information is entered then display and email the PDF
    //  
  }); // Initialize state

  return (
    <AppContext.Provider value={{ vars, setVars }}>
      {children}
    </AppContext.Provider>
  );
};

export default AppProvider