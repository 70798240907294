//  1.  Location.jsx
//  Fetches agents NPN from url
//  Requests Zipcode. Response with Counties containing that Zipcocde
//  Send collected information to next steps

//  2.  Requstor.jsx
//  Collects name, phone and email of requestor
//  Store Agent, Location and Requestor information
//  Send collected information to next step

//  3.  Results.jsx
//  Displays report as PDF.
//  If request contains email address, then send it to the requestor.

//  Done.

import React, { useContext, useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppContext  } from '../context/AppContext';
import myImage from '../assets/icon-72x72.png'
import { Stack, TextField, Button } from '@mui/material'
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

const BASE_URL = 'https://sea-lion-app-6ajbn.ondigitalocean.app';

const Location = () => {

  // Access vars and setVars from your context
  const { vars, setVars } = useContext(AppContext);  

  // Use URLSearchParams to parse query parameters
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const npn = queryParams.get('npn'); // 'npn' is the name of the query parameter
  console.log('NPN = ', npn); 

  const [myAgent, setMyAgent] = useState([]);
  const [myUser, setMyUser] = useState([])
  //const [profile, setProfile] = useState([]);
  const [users, setUsers] = useState([]);
  const [zipcode, setZipcode] = useState('');
  const [countyList, setCountyList] = useState([]); 

  // ========================================================================================================  
  // Store npn in vars from params
  useEffect(() => {
    // Update the context only if npn is not null or undefined
    if (npn) {
      setVars(prevVars => ({
        ...prevVars,
        npn: npn // npn // Update npn from url
      }));
    }
  }, [npn, setVars]);


// ========================================================================================================  
// Get agent info from agents.

useEffect(() => {
  if (npn) {
    // Fetch data from server
    const fetchData = async () => {
      const getUrl = `/agentbynpn/${npn}`
      const urlAgents = BASE_URL+getUrl
      try {
        const response = await fetch(urlAgents);
        const data = await response.json();
        setMyAgent(data); // Step 7
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }
}, [npn, setVars]); // Dependency array ensures this runs only when npn changes

// ========================================================================================================  
// Get user info from users. 

// Function to fetch data from the API based on the entered ZipCode
//  Get all counties that match zip code
const fetchMyUser = async () => {
  const urlMyUser = `https://sea-lion-app-6ajbn.ondigitalocean.app/user/${myAgent.username}`;
  //  result is a list of counties where the zlipcode is listed.
  try {
    //console.log ('urlMyUser: ',urlMyUser);
    const response = await fetch(urlMyUser);
    const data = await response.json();
    setMyUser(data); // Assuming data is in the expected format
    //console.log('Data: ', data)
  } catch (error) {
    console.error('fetchMyUser- Error fetching data:', error);
  }
};

useEffect(() => {
  fetchMyUser()
  if (myUser) 
    console.log('fetchMyUser',  myUser)
}, [myUser.username])
  // ========================================================================================================    
  // Store lZipcode in vars
  useEffect(() => {
    // Update the context only if npn is not null or undefined
    if (zipcode) {
      setVars(prevVars => ({
        ...prevVars,
        zipcode: zipcode // Update npn in your state
      }));
    }
  }, [zipcode, setVars]);
  // ======================================================================================================== 
  // ========================================================================================================    
  // Store profile.usernme in vars
  useEffect(() => {
    // Update the context only if npn is not null or undefined
    if (myAgent.username) {
      setVars(prevVars => ({
        ...prevVars,
        username: myAgent.username // Update npn in your state
      }));
    }
  }, [myAgent.username, setVars]);
  // ======================================================================================================== 
  const navigate = useNavigate();
  const goButtonGo = (newCountyFIPS, newStateCode, newCountyName) => {
    console.log('goButtonGo', myAgent.username, myAgent.npn,'CoFips:', newCountyFIPS, 'St: ', newStateCode, 'CoName:', newCountyName, zipcode);
    setVars(prevVars => ({
      ...prevVars,
      countyfips: newCountyFIPS,
      countyname:  newCountyName,
      statecode: newStateCode
    }));
    navigate(`/requestor`);
    //${profile.username}/${profile.npn}/${newCountyFIPS}/${newStateCode}/${newCountyName}/${lZipcode}`);
  
  }
// ========================================================================================================  


// Function to fetch data from the API based on the entered ZipCode
//  Get all counties that match zip code
const fetchCountyListByZipcode = async () => {
  const urlZipCode = `https://walrus-app-nzaox.ondigitalocean.app/zipcodes/counties/${zipcode}`;
  //  result is a list of counties where the zlipcode is listed.
  try {
    //console.log ('urlZipCode: ',urlZipCode);
    const response = await fetch(urlZipCode);
    const data = await response.json();

    setCountyList(data); // Assuming data is in the expected format
    //console.log('ReportData: ', reportData)
  } catch (error) {
    console.error('Error fetching data:', error);
  }
};  

useEffect(() => {
  if (zipcode && zipcode.length===5) {
    fetchCountyListByZipcode();
    setVars(prevVars => ({
      ...prevVars,
      zipcode: zipcode // Update npn in your state
    }));

    //console.log('countyList=', countyList)
  }
}, [zipcode]);
// ========================================================================================================  

console.log(vars);  // ======================================================================================================== 
  // ======================================================================================================== 
  
  return (
    <div className="container">

    <div style={{ display: 'flex', alignItems: 'center' }}>
      <img className='logo-stuff' width='72' src={myImage} alt="My Description" style={{ marginRight: '10px' }} />
      <h3>TPMO Solutions Report</h3>
    </div>
<div>    
    {myAgent ? (
      <div><h3 className="center">{myAgent.firstname} {myAgent.lastname}</h3></div>
    ) : (
      <div>Loading data...</div>
    )}
  </div>
    
    <p className="center">Agent NPN: {myAgent.npn}</p>
    
    <p className="center">
    Generate report for ZipCode:

    <h3>Zipcode:</h3>
    <Stack> 
        <TextField  variant='standard' 
          sx={{width:150}}
            value={zipcode} 
            onChange={(e) => setZipcode(e.target.value)} />
    </Stack> 
    <br/> <br/>
    
    </p>
    
    {countyList.map((c, index) => (
      <span >
        <Button onClick={() => goButtonGo(c.CountyFIPS, c.StateCode, c.CountyName)}>
          <ArrowRightIcon fontSize="small" color="primary" /> 
           {c.CountyName} County {c.StateCode} | County FIPS {c.CountyFIPS}  </Button>
      </span>
    ))}
    </div>
  )
}

export default Location;